<template>
	<div class="details">
		<Selectshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				<ul class="nav_list">
					<li><a class="a_active" href="">产品介绍</a></li>
					<li><a href="">服务流程</a></li>
					<li><a href="">服务优势</a></li>
					<li><a href="">常见问题</a></li>
				</ul>

         <!-- 万方的优势 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     什么是云堡垒机？
				   </h3>
				    <div class="kai_items">
				    	<div class="kai_de">
<!-- 				    		<h4>价格优势</h4> -->
				    		<p style="margin-bottom:20px;margin-top:-20px;"><span></span>云堡垒机是用于提供云计算安全管控的系统和组件，可以实现对运维资源的4A安全管控。相比传统堡垒机，云堡垒机的运维成本更低，部署更简单，能够满足中小企业的运维安全管理的需求，同时也是企业上云满足等保合规的好帮手。</p>
				    		
				       </div>
				    	<img class="kai_icons" style="margin-top:-24px;" src="@/assets/images/fort/a03.jpg">
				    </div>
				</div>
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     云堡垒机具备哪些功能
				   </h3>
				    <p class="deng_item">
				   	<span></span>
                    云堡垒机基于账号、认证、授权、审计的 4A统一管理方案，通过身份管理、授权管理、双因子认证、实时会话监控与切断、审计录像回放、高危指令查询等功能， 增强运维管理的安全性。
				   </p>
				   <div  class="fortblj">
				   	 <img src="@/assets/images/fort/a02.jpg">
				   </div>
				     <!-- <p>
                    	<a style="margin-top:10px;margin-bottom:30px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p>			  -->
				</div>

				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     为什么选择云堡垒机
				   </h3>
				   <ul class="fort_lists">
				   	 <li><span>1</span>B/S架构管理，支持所有主流浏览器与操作系统。</li>
				   	 <li><span>2</span>精简的嵌入式Linux系统，保障系统安全性。</li>
				   	 <li><span>3</span>产品成熟、稳定、注重用户体验，操作便捷。</li>
				   	 <li><span>4</span>满足等保2.0技术要求的身份鉴别、访问控制和安全审计等。</li>
				   </ul>
				
				   
				    			 
				</div>
				

				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     万方公有云二级等保整改套餐
				   </h3>
				    <p class="deng_item">
				   	<span></span>
                    万方为广大公有云（阿里云、腾讯云、华为云）等云平台客户提供二级等保整改全套安全产品保证客户满足二级等保整改要求，同时也会配套提供安全整改服务，让客户更省心、省事的通过等保测评
				   </p>
				 <table class="table_style" border="1">
					  <tr>
					    <th>产品中心</th>
					    <th>合规对应要求</th>
					  </tr>
					  <tr>
					    <td>云防火墙</td>
					    <td>满足等保要求中的边界防护和访问控制等要求</td>
					  </tr>
					  <tr>
					    <td>云安全中心</td>
					    <td>满足等保要求中安全审计和集中管控的要求</td>
					  </tr>
					  <tr>
					    <td>云日志审计</td>
					    <td>满足等保要求中日志审计和存储时间的要求</td>
					  </tr>
					  <tr>
					    <td>云堡垒机</td>
					    <td>满足等保要求中身份鉴别、访问控制和安全审计等要求</td>
					  </tr>
					</table>
					 <!-- <p>
                    	<a class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->
				</div>
				
				<!-- 万方资质 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     万方资质
				   </h3>
				   <img style="width:100%;" src="@/assets/images/dengbaomeal/a06.jpg">
				</div>
				<!-- 行业案例 -->
				<div class="con_list">
					<h3>
						<img class="icons" src="@/assets/images/b04.png">
					     行业案例
				   </h3>
				   <ul class="case">
				   	 <li>
				   		<span class="tis">1.医疗行业</span>
				   		<img class="case_img" src="@/assets/images/b17.png">
				   		<div class="case_items">
				   			<h6>丽珠医药集团股份有限公司</h6>
				   			<div class="case_items_list">
				   				<span>需求：</span>等保三级
				   			</div>
				   			<div class="case_items_list">
				   				<span>周期：</span>2个月时间完成，由于客户新开业务，整改配套设施耗时较长；
				   			</div>
				   			<div class="case_items_list">
				   				<span>收益：</span>(1)满足合规性需求；<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身医疗系统经过安全测评及整改，系统安全性有基本的了解及提高。
				   			</div>
				   		</div>
				   	 </li>
				   	  <li>
				   		<span class="tis">2.金融行业</span>
				   		<img class="case_img" src="@/assets/images/b18.png">
				   		<div class="case_items">
				   			<h6>东莞市德吉特软件科技有限公司</h6>
				   			<div class="case_items_list">
				   				<span>需求：</span>等保三级
				   			</div>
				   			<div class="case_items_list">
				   				<span>周期：</span>1.5个月完成；
				   			</div>
				   			<div class="case_items_list">
				   				<span>收益：</span>(1)满足金融行业监管需求；<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身金融业务系统经过安全测评及整改，系统安全性有基本的了解， 并从根本上得以提高。
				   			</div>
				   		</div>
				   	 </li>
				   	  <li>
				   		<span class="tis">3.教育行业</span>
				   		<img class="case_img" src="@/assets/images/b19.png">
				   		<div class="case_items">
				   			<h6>深圳市前海美联科技有限公司</h6>
				   			<div class="case_items_list">
				   				<span>需求：</span>等保三级
				   			</div>
				   			<div class="case_items_list">
				   				<span>周期：</span>2个月时间完成;
				   			</div>
				   			<div class="case_items_list">
				   				<span>收益：</span>(1)满足合规性需求<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身在线教育系统经过安全测评及整改，系统安全性有基本的了解及提高。
				   			</div>
				   		</div>
				   	 </li>
				   </ul>
				   <span class="more_case">更多案例请点击</span>
				   <!-- <p style="" class="kai_desc1">等保测评刻不容缓，找万方！</p> -->
				     <!-- <p>
                    	<a style="margin-top:10px;margin-bottom:30px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">
                    	   <img class="kai_phone" src="@/assets/images/b11.png">
                       </a>
                    </p> -->
				</div>
	
			</div>
			<!-- right -->
			<Hotgoods/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Hotgoods from "@/components/hotgoods.vue"
import Selectshop from "@/components/Selectshop.vue"

export default{
	components:{
      Hotgoods,
      Selectshop
	},
data(){
	return{
		transmitData:{
			title:"云堡垒机",
			content:"相比传统堡垒机，云堡垒机的运维成本更低，部署更简单，能够满足中小企业的运维安全管理的需求，同时也是企业上云满足等保合规的好帮手",
			price:'在线咨询',
			url:require('../../assets/images/fort/a01.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;
//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	padding:0 35px;
	overflow:hidden;
}
.con_list img{
	// width:100%;
}
.con_list h3{
	font-size: 22px;
	font-weight: bold;
	color: #2B2B2B;
	line-height: 42px;
	margin-top:40px;
}
.icons{
	width:11px;
	height:28px;
	position:relative;
	top:-2px;
}
.meal_box{
	width:800px;
	margin:10px auto;
	display: flex;
	justify-content: space-between;
}
.meal_box>li>img{
	width:85px;
	height:85px;
	padding-top:7px;
}
.meal_box>li{
	width:120px;
	height:120px;
	padding:10px;
	text-align:center;
	border:2px solid #007CC6;
	border-radius: 50%;
}
.meal_box>li>span{
	display: block;
	text-align:center;
	font-size:16px;
}
.deng_item {
	position:relative;
	font-size: 16px;
	font-weight: 300;
	color: #2B2B2B;
	line-height: 23px;
	margin-left:20px;
	margin-top:15px;
}
.deng_item span{
	width: 6px;
	height:6px;
	border: 2px solid #007CC6;
	border-radius: 50%;
	position:absolute;
	top:5px;
	left:-18px;
}
.table_style{
	border-collapse: collapse;
	text-align:center;
	font-size:16px;
	line-height: 40px;
	margin:20px auto;
	border-color:#0e82cb;
}
.table_style th{
	background:#007CC6;
	color:#fff;
}
.table_style th:nth-of-type(1){
	width:200px;
}
.table_style th:nth-of-type(2){
	width:500px;
}
.table_style td:nth-of-type(2){
  padding-left:20px;
  text-align:left;
}
.kai_phone_box{
	display: inline-block;
	margin:0 auto;
	position:relative;
	margin-top:35px;
	left:50%;
	width:250px;
	margin-left:-125px;
}
.kai_items{
	margin-top:12px;
    overflow:hidden;
	font-size: 17px;
	font-weight: 300;
	color: #2B2B2B;
	line-height: 23px;
	display: table;
}
.kai_icons{
	width: 190px;
    height: 190px;
    float:left;
    margin:0 20px;
}
.kai_items>.kai_de{
   display: table-cell;
   vertical-align: middle;
   width: 629px;
   height:138px;
}
.kai_items>.kai_de h4{
	color:#0e82cb;
	font-size:20px;
	margin-left:10px;
}
.kai_items>.kai_de span{
	width: 6px;
    height: 6px;
    border: 2px solid #007CC6;
    border-radius: 50%;
    display: inline-block;
    margin-right:5px;
}
.kai_items>.kai_de p{
	font-size:17px;
	margin:10px;
	line-height:30px;
}
.case li{
	position:relative;
	width: 769px;
	height: 221px;
	background: #FFFFFF;
	border: 1px solid #268CF5;
	border-radius: 0px 0px 20px 0px;
	margin:0 auto;
	margin-top:30px;
	display: flex;
	// align-items:center;
}
.case li .tis{
   width: 140px;
	height: 35px;
	display: inline-block;
	background: linear-gradient(-75deg, #278BF5 0%, #03A9F7 76%);
	border: 1px solid #268CF5;
	border-radius: 20px 0px 0px 0px;
	text-align:center;
	line-height:35px;
	color:#fff;
	position:absolute;
	top:-19px;
	left:0px;
}
.case li .case_img{
	width:150px;
	height:58px;
	margin-top:90px;
}
.case_items{
	flex:1;
	padding:10px;
}
.case_items h6{
	font-size:20px;
	font-weight: bold;
	color: #268CF5;
	line-height: 42px;
	margin-top:15px;
}
.case_items_list{
	font-size:16px;
	color: #272626;
	line-height: 26px;
	// display: flex;
}
.more_case{
	width: 159px;
	height: 41px;
	font-size:12px;
	line-height: 40px;
	display: block;
	margin:10px auto;
	text-align:center;
	color:#157AE4;
	border: 1px solid #007CC6;
	cursor:pointer;
}
.kai_desc1{
	font-size:19px;
	font-weight: bold;
	color: #007CC6;
	line-height: 42px;
	text-align:center;
}

.kai_desc2{
	font-size:17px;
	color: #007CC6;
	line-height: 32px;
	text-align:center;
	margin-top:10px;
}
.fortblj{
	margin:0 auto;
	text-align:center;
}
.fortblj>img{
	width:300px;
	margin-top:30px;
}
.fort_lists{
	margin-top:5px;
}
.fort_lists li{
	margin-top:9px;
	font-size:16px;
	line-height: 30px;
}
.fort_lists li span{
	width:20px;
	height:20px;
	border:1px solid #007CC6;
	border-radius:50%;
	text-align:center;
	line-height: 20px;
	font-size:12px;
	color:#007CC6;
	margin:0 10px;
	display: inline-block;
}





}


</style>







